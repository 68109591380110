@import "./color-functions.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import "@fontsource/montserrat";

$body-bg: color-level("background", 1);
$body-color: color-level("primary", 1);

@import "~bootstrap/scss/bootstrap";

$break-small: 400px;
$break-medium: 767px; /* Landscape phone to portrait tablet */
$break-large: 1200px;

$primaryColor: rgb(4, 54, 112);
$secondaryColor: rgb(255, 255, 255);

// BASIC-ELEMENTS
a {
  color: color-level("primary", 1);
}

a:hover {
  color: color-alpha("primary", "light");
  text-decoration-line: inherit;
}

// BOOSTRAPS-OVERWRITE
.btn-primary {
  background-color: color-level("primary", 1);
}

.btn-primary:hover {
  background-color: color-level("primary", 2);
}

.btn-primary:active {
  background-color: color-level("primary", 3);
}

.btn-secondary {
  background-color: color-level("secondary", 2);
}

.btn-common {
  background-color: color-level("common", 1);
  color: white;
}

.btn-common:hover {
  background-color: color-level("common", 2);
}

.btn-common:active {
  background-color: color-level("common", 3);
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.modal-backdrop {
  z-index: 30 !important;
}

.MuiBackdrop-root {
  backdrop-filter: blur(7px);
}

#bola__pulsante {
  animation: pulsante 0.5s ease-in-out;
}

@keyframes pulsante {
  0% {
    box-shadow: #19568c 0 0 0 0;
  }
  100% {
    box-shadow: #ffffff00 0rem 0rem 0rem 1rem;
  }
}

#modal__show {
  animation: popup 0.7s;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

//   #appLoading {
//     z-index:999;
//     opacity:.7;
//     /*  -moz-filter:progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
//     -ms-filter:progid: DXImageTransform.Microsoft.Alpha(Opacity=70);
//     filter:progid: DXImageTransform.Microsoft.Alpha(Opacity=70);*/
//     background-color:#fff;
//     width:100%;
//     height:100%;
//     display:-ms-flexbox;
//     display:flex;
//     -ms-flex-align:center;
//     align-items:center;
//     -ms-flex-flow:column nowrap;
//     flex-flow:column nowrap;
//     -ms-flex-pack:distribute;
//     justify-content:space-around;
//     position:fixed;
//     top:0;
//     left:0;
//     & > .loading-container {
//         -ms-flex:0 1 auto;
//         flex:0 1 auto;
//         display:-ms-flexbox;
//         display:flex;
//         -ms-flex-flow:column nowrap;
//         flex-flow:column nowrap;
//         -ms-flex-pack:justify;
//         justify-content:space-between;
//         & > img {
//             width:66px;
//             height:66px;
//             -ms-flex-item-align:center;
//             -ms-grid-row-align:center;
//             align-self:center;
//         }
//         &  > span {
//             margin-top:24px;
//         }
//     }
// }

$gradient-start: #ffffff;
$gradient-end: #ffffff;
.menu {
  background-image: linear-gradient(
    to right,
    $gradient-start 0%,
    $gradient-end 100%
  );
  border-radius: 0.5rem;
  text-transform: uppercase;
  a {
    font-size: "1.2rem";
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    &:focus,
    &:focus-within {
      outline: none;
    }
  }
  > ol {
    display: flex;
    > li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media (min-width: 45rem) {
  .menu {
    > ol {
      justify-content: space-around;
      &:hover,
      &:focus-within {
        > li:first-child {
          > a {
            opacity: 0.6;
          }
        }
      }
      > li {
        flex: 1 1 0;
        &:last-child:after {
          content: "";
          position: absolute;
          top: 0;
          width: 100%;
          bottom: 0;
          margin-right: -50%;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 0.5rem;
          z-index: 0;
          transition: 350ms cubic-bezier(1, 0.49, 0.09, 1.29) all;
          pointer-events: none;
        }
        @for $i from 1 to 12 {
          &:first-child:nth-last-child(#{$i}) ~ li:last-child:after {
            right: 50% + 100% * ($i - 1);
          }
        }
        @for $i from 1 to 12 {
          &:first-child:nth-last-child(#{$i}),
          &:first-child:nth-last-child(#{$i}) ~ li {
            @for $j from 1 to $i {
              &:nth-child(#{$j}):hover,
              &:nth-child(#{$j}):focus,
              &:nth-child(#{$j}):focus-within {
                ~ li:last-child:after {
                  right: 50% + 100% * ($i - $j);
                }
                > ol a {
                  box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0),
                    inset 0 0 0 3rem rgba(237, 110, 160, (1 - (1 / $i) * $j));
                  &:hover,
                  &:focus,
                  &:focus-within {
                    box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0.1),
                      inset 0 0 0 3rem rgba(237, 110, 160, (1 - (1 / $i) * $j));
                  }
                }
              }
              &:last-child:hover:after,
              &:last-child:focus:after,
              &:last-child:focus-within:after {
                right: 50%;
              }
            }
          }
        }
        &:first-child {
          > a {
            opacity: 1;
          }
        }
        > a {
          padding: 2rem 0;
          font-size: 1.2rem;
          position: relative;
          width: 100%;
          text-align: center;
          z-index: 1;
          opacity: 0.7;
          transition: 250ms ease all;
        }
        > ol {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          visibility: hidden;
          > li {
            margin-top: 0.5rem;
            transition: 250ms cubic-bezier(0.42, 0.83, 0.49, 1.35) transform;
            transform: scale(0);
            > a {
              display: block;
              text-align: center;
              border-radius: 0.2rem;
              padding: 1rem 0.5rem;
              font-size: 1.2rem;
              background: $gradient-end;
              color: rgba(255, 255, 255, 0.6);
              transition: 250ms ease all;
              &:hover,
              &:focus,
              &:focus-within {
                box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0.1);
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
        &:hover,
        &:focus,
        &:focus-within {
          > a {
            opacity: 1 !important;
          }
          > ol {
            visibility: visible;
            > li {
              transform: scale(1);
              &:first-child {
                transition-delay: 150ms;
              }
            }
            @for $k from 1 to 12 {
              > li:nth-child(#{$k}) {
                transition-delay: 150ms * $k;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 45rem) {
  .menu {
    background-image: linear-gradient(
      to bottom,
      $gradient-start 0%,
      $gradient-end 100%
    );
    a {
      font-size: 1.2rem;
      padding: 1rem 0.5rem;
    }
    > ol {
      flex-direction: column;
      > li {
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        > a:nth-last-child(2) {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -0.25rem;
            right: 1.5rem;
            border-top: 0.5rem solid #fff;
            border-left: 0.3rem solid transparent;
            border-right: 0.3rem solid transparent;
            transition: 250ms ease all;
          }
        }
        &:focus-within,
        &:focus,
        > a:hover,
        > a:focus-within,
        > a:focus {
          &:nth-last-child(2) {
            &:after {
              transform: rotate(180deg);
            }
          }
          + ol {
            max-height: 12rem;
          }
        }
        > ol {
          background: rgba(0, 0, 0, 0.1);
          box-shadow: inset 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
          overflow: hidden;
          max-height: 0;
          transition: 250ms ease all;
          > li {
            a {
              display: block;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

.loader {
  animation: spinningColor 1.5s ease-in-out infinite;
  margin: 50px auto;
  border: 5px double #f0eff5;
  border-radius: 50%;
}

@keyframes spinningColor {
  0% {
    transform: rotate(360deg);
    border-top: 5px dashed #f56682;
    border-bottom: 5px dashed #387eff;
  }
  25% {
    border-top: 5px dashed #f591a6;
    border-bottom: 5px dashed #6da7f7;
  }
  50% {
    border-top: 5px dashed #fd878e;
    border-bottom: 5px dashed #4ba3ff;
  }
  75% {
    border-top: 5px dashed #f57f8f;
    border-bottom: 5px dashed #569dff;
  }
  100% {
    border-top: 5px dashed #f56682;
    border-bottom: 5px dashed #387eff;
  }
}

.fab__zoom {
  width: 20em;
  height: 4rem;
  border-radius: 5rem;
  border: 1px solid #19568c;
  background-color: #19568c;
  transition: transform 0.2s; /* Animation */
  color: #f0eff5;
  box-shadow: 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
}

.fab__zoom:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  box-shadow: 0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  background-color: rgb(4, 54, 112);
}

.text-message {
  white-space: pre-line;
}

.searchButton {
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0869AB;
  border: none;
  width: 5rem;
  height: 4.3rem;
  margin-left: -1rem;
  display: flex;
  place-items: center;
  place-self: center;
  justify-content: center;
  z-index: 999999;
  position: relative;
  right: 10px;
}

.MuiTableRow-root.MuiTableRow-footer {
  &:hover {
    background-color: transparent;
  }
}

#btnVolver {
    position: absolute;
    top: 5%;
    left: 0;
}

.MuiInputBase-input.MuiInput-input.css-15e8ec1-MuiInputBase-input-MuiInput-input {
  transform: translateX(10px);
}

.div-media-card {
  width: 100%;
  margin: 0 0 2rem 0;
}

.card-table {
  background-color: transparent !important;
}

.tableBaseStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.table-searchBar {
  font-family: "Montserrat", "Poppins", "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 65px;
  padding: 16px;
  justify-content: space-between;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.8);
}

.table-searchInputContainer {
  font-family: "Montserrat", "Poppins", "Open Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  position: relative;
}

.table-searchInput {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  position: relative;
  z-index: 1;
  font-size: 1.2rem;
}

.table-searchIcon {
  position: absolute;
  left: 10px;
  z-index: 2;
  margin: 5px 0 0 0;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  padding: 16px;
  gap: 16px;
  font-weight: 600;
  justify-content: space-between;
  font-family: "Montserrat", "Poppins", "Open Sans";
  background-color: rgba(255, 255, 255, 0.8);
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 36px;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  font-size: 14px;
  opacity: 1;
  max-height: 100px; /* Un valor suficientemente grande para asegurar que la fila se muestra completamente */
  transition: opacity 0.3s ease, max-height 0.3s ease,
  background-color 0.3s ease;
  font-size: 1.2rem;
  font-family: "Montserrat", "Poppins", "Open Sans";
  background-color: rgba(255, 255, 255, 1);
}

.table-row.hidden {
  opacity: 0;
  max-height: 0 !important;
  padding: 0; /* Opcional: elimina el relleno cuando la fila está oculta */
}

.table-row:hover {
  background-color: #E6E5E5;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 65px;
  padding: 16px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.8);
  gap: 12px;
}

.table-colCuit {
  width: 15%;
  font-size: 1.2rem;
}

.table-colRazonSocial {
  width: 30%;
  font-size: 1.2rem;
}

.table-colVigencia {
  width: 15%;
  font-size: 1.2rem;
}

.table-colTipo {
  width: 20%;
  font-size: 1.2rem;
}

.table-colEstado {
  width: 10%;
  font-size: 1.2rem;
}

.table-colPendientes {
  width: 13%;
  font-size: 1.2rem;
}

.table-colActions {
  width: 10%;
  font-size: 1.2rem;
}

.table-modal {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(7px);
  border-radius: 12px;
  width: 60dvw;
  min-height: 60dvh;
  overflow-y: auto;
  max-height: 90dvh;
  position: relative;
  max-width: 800px;
}

.table-closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
}

.table-contentModal {
  padding: 50px 30px 15px 30px;
  display: flex;
  flex-direction: column;
}

.table-contentModal p,
.table-contentModal ul,
.table-contentModal li {
  margin-bottom: 5px; /* Ajusta el valor según tus necesidades */
}

.table-titleModal {
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  left: 20px;
  top: 15px;
}

.table-buttonContainerModal {
  text-align: center;
  padding: 0 0 20px 0;
}

@media (max-width: 600px) {
  .table-modal {
    width: 100dvw;
  }
  .details-card-container {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .table-modal {
    width: 80dvw;
  }
}

@keyframes fontSizeGrow {
  from {
    font-size: 1.4rem;
    font-weight: 400;
  }
  to {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

@keyframes menuFontSizeGrow {
  from {
    font-size: 1.6rem;
  }
  to {
    font-size: 1.7rem;
  }
}

.environment-card {
  position: fixed;
  display: flex;
  z-index: 999999;
  min-width: 500px;
  height: 80px;
  bottom: 10px;
  left: -410px;
  transition: left 0.3s ease;
  justify-content: end;
  align-items: center;
  font-size: 20px;
  background-color: rgba(175, 220, 70, 0.8);
  backdrop-filter: blur(7px);
  font-weight: 700;
  letter-spacing: 2px;
  color: white;
  padding-inline: 30px;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(101, 125, 46, 0.8),
    rgba(101, 125, 46, 0.8) 1rem,
    rgba(133, 158, 76, 0.8) 1rem,
    rgba(133, 158, 76, 0.8) 2rem
  );
  background-size: 200% 200%;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  animation: bars 10s infinite linear;
  border-radius: 50px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7), inset 2px 2px 10px rgba(236, 236, 44, 0.8), inset -2px -2px 10px rgba(0, 0, 0, 0.7);
}

@keyframes bars {
  100% {
    background-position: 100% 100%;
  }
}

.environment-card:hover {
  background-color: rgba(0, 0, 0, 0.8);
  left: -150px;
}

.environment-icon {
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 35px !important;
  margin-left: 30px;
}

.details-card-container {
  background-color: #FFF;
  font-family: 'Open Sans', sans-serif;
  border-radius: 1.6rem;
  width: 85%;
}

.details-card-title {
  height: 6.6rem;
  padding: 2.4rem 5.6rem 1.6rem 2.4rem;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #CDCCCC;
  position: relative;
}

.details-card-body {
  font-size: 2rem;
  padding: 2.2rem 2.4rem 0 5rem;
}

.details-card-footer {
  font-size: 2rem;
  padding: 2.4rem 5.6rem 5rem 5rem;
}

.buttons-container {
  border: 1px solid red;
  display: flex !important;
  position: sticky !important;
  bottom: 5rem !important;
}

.sticky-div {
  pointer-events: none !important;
  display: flex !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  bottom: 0px !important;
  z-index: 900;
  padding-left: 10vw !important;
}

.sticky-background {
  pointer-events: none !important;
  display: flex !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  background: linear-gradient(180deg, transparent 0%, #b0cae0 30%, #b0cae0 70%, transparent 100%);
  bottom: 0px !important;
  height:250px;
  width: 100%;
  z-index: 1;
}

.sticky-button {
  pointer-events: auto !important;
  z-index: 1000;
}

.MuiButtonBase-root.Mui-disabled.MuiButton-root.MuiButton-bold.MuiButton-boldPrimary.MuiButton-sizeMedium.MuiButton-boldSizeMedium.sticky-button:hover {
  background-color: inherit !important;
}

.MuiButtonBase-root.Mui-disabled.MuiButton-root.MuiButton-success.MuiButton-successPrimary.MuiButton-sizeMedium.MuiButton-successSizeMedium.sticky-button:hover {
  background-color: #1B3A65 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.scrollbar-arrow {
  position: sticky;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%) translateY(0.5rem) !important;
  z-index: 1 !important;
  width: 3.6rem;
  height: 3.6rem;
  color: #FFF;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

@media (max-width: 900px) {
  .sticky-div {
    display: flex !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0px !important;
    width: 100%;
    padding-top: 20rem;
    // background: linear-gradient(180deg, transparent 0%, #b0cae0 30%);
    // background-color: red;
    padding-inline: 2rem;
    padding-left: 2rem !important;
  }

  .sticky-background {
    background: linear-gradient(180deg, transparent 0%, #b0cae0 20%);
  }
}

@media (max-height: 768px) {
  // .scroll-bars {
  //   height: 35vh !important;
  // }
}
input:-webkit-autofill { 
    -webkit-background-clip: text;
}

.apple-auth-btn {
  width: 25rem;
  height: 4.2rem;
  border-radius: 20rem !important;
  font-size: 1.2rem !important;
}